import { useEffect, useState } from "react";
import { useUtilityStore } from "../zustand/utilityStores";
import { collection, getDocs, query } from "firebase/firestore";
import { db } from "../firebase/setupFirebase";
import { claimGift } from "../firebase/functions";
import { Link } from "react-router-dom";
var ccodes = require("currency-codes");

interface Gift {
  duration_days: number;
  id: string;
}

export default function SubsriberScreen() {
  const isPro = useUtilityStore((state) => state.isPro);
  const proDate = useUtilityStore((state) => state.freeProUntil);
  const didFetchSubscriberInfo = useUtilityStore(
    (state) => state.didFetchSubscriberInfo
  );
  const uid = useUtilityStore((state) => state.uid);
  const [gifts, setGifts] = useState<Gift[]>([]);
  const [giftError, setGiftError] = useState<string | null>(null);
  const [isClaiming, setIsClaiming] = useState(false);
  const ccInfo = useUtilityStore((state) => state.ccInfo);
  const epochInfo = useUtilityStore((state) => state.epochInfo);

  useEffect(() => {
    async function fetchData() {
      const giftsRef = collection(db, `subscriber_info/${uid}/gifts`);
      const giftsQuery = query(giftsRef);
      const querySnapshot = await getDocs(giftsQuery);

      const docs: any = [];
      querySnapshot.forEach((doc) => {
        docs.push({ ...doc.data() });
      });

      setGifts(docs);
    }

    if (uid) {
      fetchData();
    }
  }, [uid]);

  const onClickClaimGift = async (giftId: string) => {
    try {
      setIsClaiming(true);
      await claimGift(giftId);
      setIsClaiming(false);

      // Reload page after 1 second to update subscriber info
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (err: any) {
      setGiftError("Error: " + err.message);
      console.error(err);
    }
  };

  const GiftsList = gifts.map((gift) => {
    return (
      <div key={gift.id}>
        <div>
          <button
            onClick={() => onClickClaimGift(gift.id)}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg disabled:opacity-30"
            disabled={isClaiming}
          >
            Claim {gift.duration_days} days
          </button>
        </div>
      </div>
    );
  });

  let ccDash = null;
  if (ccInfo && ccInfo.cc_next_renewal_date) {
    const nextRenewalDate = new Date(
      ccInfo.cc_next_renewal_date
    ).toDateString();

    const lastChargeDate = new Date(ccInfo.cc_last_charge_date).toDateString();

    const subscriptionId = ccInfo.cc_subscription_id;
    const email = ccInfo.cc_email;
    const last4 = ccInfo.cc_last4;
    const expDate = ccInfo.cc_exp_date;
    let status = ccInfo.cc_subscription_status;

    // If nextRenewal date is in the past, status is expired
    if (new Date(nextRenewalDate) < new Date()) {
      status = "Expired";
    }

    const price = ccInfo.cc_price;
    let currency = "USD";
    if (price) {
      currency = ccodes.number(ccInfo.cc_currency_code)?.symbol || "USD";
    }
    const showFreeTrial = false;

    ccDash = (
      <div className="border rounded-md p-4 m-4">
        <div className="text-lg font-bold text-center text-yellow-400">
          CCBill Pro Membership
        </div>
        <div className="font-bold">Status: {status}</div>
        <div>
          Price: {price} {currency} per month
        </div>
        <div>Next Renewal Date: {nextRenewalDate}</div>
        {!showFreeTrial && <div>Last Charge Date: {lastChargeDate}</div>}
        {last4 && <div>Credit Card: {last4}</div>}
        {expDate && <div>Exp Date: {expDate}</div>}
        <br />
        <div className="font-bold">CCBill Info</div>
        <div>Email: {email}</div>
        <div>Subscription ID: {subscriptionId}</div>
        <br />
        <a className="underline text-white" href="https://support.ccbill.com/">
          Manage Subscription
        </a>
        <br />
        <a
          className="underline text-red-500"
          href="https://support.ccbill.com/"
        >
          Cancel Subscription
        </a>
      </div>
    );
  }

  let epochDash = null;
  if (epochInfo && epochInfo.epoch_next_renewal_date) {
    const nextRenewalDate = new Date(
      epochInfo.epoch_next_renewal_date
    ).toDateString();
    const lastChargeDate = new Date(
      epochInfo.epoch_last_charge_date
    ).toDateString();
    const email = epochInfo.epoch_email;
    const price = epochInfo.epoch_local_amount || "15.00";
    const currency = epochInfo.epoch_currency || "USD";

    epochDash = (
      <div className="border rounded-md p-4 m-4">
        <div className="text-lg font-bold text-center text-yellow-400">
          Epoch Pro Membership
        </div>
        <div className="font-bold">Status: {epochInfo.epoch_status}</div>
        <div>
          Price: {price} {currency} per month
        </div>
        <div>Next Renewal Date: {nextRenewalDate}</div>
        <div>Last Charge Date: {lastChargeDate}</div>
        <br />
        <div className="font-bold">Epoch Info</div>
        <div>Epoch Email: {email}</div>
        <div>Member ID: {epochInfo.epoch_member_id}</div>
        <br />
        <a className="underline text-white font-bold" href="https://epoch.com/">
          Manage/Cancel Subscription
        </a>
        <div className="font-bold">
          You will need your Member ID and email above to cancel
        </div>
      </div>
    );
  }

  return (
    <div className="text-white max-w-lg pb-16">
      <div className="text-xl text-center font-bold">Subscriptions</div>
      {!isPro && (
        <div className="mb-8 text-center mt-4 text-xl">
          <div>You do not have a subscription</div>
          <Link className="underline" to="/getpro">
            Subscribe to Pro
          </Link>{" "}
        </div>
      )}
      {!didFetchSubscriberInfo && (
        <div className="text-center animate-bounce">Loading...</div>
      )}
      {isPro && proDate && (
        <div className="text-center text-yellow-400 text-lg">
          You have been awarded free Pro mode until{" "}
          {proDate.toLocaleDateString()}!
        </div>
      )}
      {epochDash}
      {ccDash}
      {gifts.length > 0 && (
        <div className="mt-8 px-4 text-center">
          <div className="text-xl text-center font-bold">Gifts</div>
          <div className="mb-4">
            You can only claim a gift if you are not currently a Pro member.
            Please wait until your membership expires before claiming your gift.
          </div>
          {giftError && <div className="text-red-300">{giftError}</div>}
          {GiftsList}
        </div>
      )}
    </div>
  );
}
